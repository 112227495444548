<script setup>
import { computed, inject, onMounted, onUnmounted } from 'vue'
import { useStore } from 'vuex'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import './AboutPage.scss'
import loaderImg from '@/components/Loader/loader-img.vue'
import LoaderSequence from '@/components/Loader/loader-sequence.vue'
import ImgLoadScroll from '@/components/ImageLoadOnScrol/img-load-scroll.vue'
import SkarallaxBlock from '@/components/skarallax/skarallax-block.vue'

const store = useStore()

const isMobile = inject('isMobileCheck')
const isRetina = inject('isRetina')

gsap.registerPlugin(ScrollTrigger)
function handleTest() {
	isHighDensity()
}
function handleLoadAllPictures() {
	store.dispatch('preloader/actReadyToLoad', true)
}
const loaderAllPicsAmount = computed(() => {
	return store.getters['preloader/allPicsAmount']
})
const picsToLoad = computed(() => {
	return store.getters['preloader/picsToLoad']
})

const isRetinaComp = computed(() => {
	return isRetina
})
onMounted(() => {
	console.log(`mount aboutpage`)
})
function isHighDensity() {
	console.log(isRetina.value)
}
onUnmounted(() => {
	console.log(`unmount aboutPage`)
	store.dispatch('preloader/resetPreloader')
})
</script>
<template>
	<div class="about-page">
		<strong>
			Осталось <b style="color: red">{{ picsToLoad }}</b> из
			<i style="font-style: normal; color: green">{{ loaderAllPicsAmount }}</i> картинок
		</strong>
		<button @click="handleLoadAllPictures">Загрузить все в очереди</button>

		<div
			class="spacer"
			style="
				height: 1000px;
				background: #666;
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				justify-content: center;
				align-items: center;
				border: 5px dashed #fdd094;
			"
		>
			<span>spacer</span>
		</div>
		<div class="scale-block" style="overflow: hidden">
			<skarallax-block
				:y="0"
				:scale-from="1"
				:scale-to="1.3"
				style="position: relative; z-index: 6"
			>
				<loader-img src="assets/images/static/girl.png" :width="1295" :height="913" />
			</skarallax-block>
		</div>

		<skarallax-block :y="500" style="position: relative; z-index: 5">
			<div class="spacer" style="background: yellow; width: 100%; height: 300px"></div>
		</skarallax-block>
		<img-load-scroll src="assets/images/static/girl.png" :thumb="true" :loader="true" />
		<div
			class="spacer"
			style="
				height: 1000px;
				background: #666;
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				justify-content: center;
				align-items: center;
				border: 5px dashed #fdd094;
			"
		>
			<span>spacer1</span>
		</div>
		<loader-img src="assets/images/static/1.jpg" />
		<loader-img src="assets/images/static/2.jpg" />
		<loader-sequence path="assets/images/static/sequence2" :end-frame="130" :on-scroll="false" />
	</div>
</template>
