<script setup>
import './index-third-section.scss'
import { ref, nextTick, onMounted, computed, watch } from 'vue'
import { useStore } from 'vuex'
const store = useStore()

import { gsap, Power2, Expo, Power1, Linear } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import LoaderVideo from '@/components/Loader/loader-video.vue'
import LoaderImg from '@/components/Loader/loader-img.vue'
import { CustomEase } from 'gsap/CustomEase'
import LoaderSequence from '@/components/Loader/loader-sequence.vue'
import CircleFollower from '@/components/circleFollower/circle-follower.vue'
import Follower from '@/layout/Preloader/Follower.vue'
gsap.registerPlugin(ScrollTrigger)

let animationTimeLine = gsap.timeline({
	paused: true,
	repeatDelay: 2,
	repeat: 3
})
let mainAnimationTL = gsap.timeline({
	paused: true
})
let titleAnimationTL = gsap.timeline({
	paused: true
})
const thisSect = ref()
const sectHolder = ref()
const topTextElem = ref()
const bottomTextElem = ref()
const isPlayCanvas = ref(false)
const sequenceElem = ref()
const textBGElem = ref()

const isAllLoaded = computed(() => {
	return store.getters['preloader/isAllLoaded']
})

watch(
	() => isAllLoaded.value,
	(allLoaded) => {
		console.log(`all loaded from sect`)

		animations()
	},
	{ immediate: false }
)
onMounted(() => {
	console.log(`mounted index second section`)
	scrollingAnimationsInit()
})

function animations() {
	// фиксим секцию
	const TL = gsap.timeline({
		scrollTrigger: {
			trigger: thisSect.value,
			start: 'top bottom',
			end: '+=' + window.innerHeight * 2,
			pin: sectHolder.value,
			invalidateOnRefresh: true
		}
	})

	const TLTopText1 = gsap.timeline({
		scrollTrigger: {
			trigger: thisSect.value,
			start: 'top 100%',
			end: 'bottom bottom',
			scrub: true,
			invalidateOnRefresh: true
		}
	})
	TLTopText1.fromTo(
		topTextElem.value,
		{ yPercent: 15 },
		{
			yPercent: -15,
			ease: 'none'
		},
		0
	)
	TLTopText1.fromTo(
		bottomTextElem.value,
		{ yPercent: 10 },
		{
			yPercent: -10,
			ease: 'none'
		},
		0
	)
	TLTopText1.fromTo(
		textBGElem.value,
		{ yPercent: 0 },
		{
			yPercent: -10,
			ease: 'none'
		},
		0
	)
	// TLTopText1.fromTo(
	// 	bottomTextElem.value,
	// 	{ yPercent: 45 },
	// 	{
	// 		yPercent: -45,
	// 		ease: CustomEase.create(
	// 			'custom',
	// 			'M0,0,C0,0,0.043,0.201,0.065,0.258,0.074,0.284,0.092,0.32,0.105,0.338,0.114,0.352,0.136,0.375,0.15,0.384,0.166,0.396,0.19,0.404,0.225,0.417,0.352,0.464,0.663,0.439,0.81,0.52,0.86,0.547,0.912,0.674,0.92,0.688,0.929,0.706,0.942,0.739,0.95,0.764,0.966,0.816,1,1,1,1'
	// 		)
	// 	},
	// 	0.1
	// )

	const TLTopText2 = gsap.timeline({
		scrollTrigger: {
			trigger: thisSect.value,
			start: 'top bottom',
			end: '+=' + window.innerHeight / 4,
			scrub: true,
			invalidateOnRefresh: true
		}
	})
	TLTopText2.fromTo(topTextElem.value, { opacity: 0 }, { opacity: 1, ease: 'none' }, 0)
	TLTopText2.fromTo(bottomTextElem.value, { opacity: 0 }, { opacity: 1, ease: 'none' }, 0.6)

	const TLBgIn = gsap.timeline({
		scrollTrigger: {
			trigger: thisSect.value,
			start: 'top bottom',
			end: '+=' + 500,
			scrub: true,
			invalidateOnRefresh: true
		}
	})
	TLTopText2.fromTo(sequenceElem.value, { opacity: 0, scale: 0.5 }, { opacity: 1, scale: 1, ease: 'none' }, 0)
	//большой текст, затемнение
	const TL3 = gsap.timeline({
		scrollTrigger: {
			trigger: thisSect.value,
			start: 'top bottom',
			end: '+=' + window.innerHeight,
			scrub: true,
			invalidateOnRefresh: true
		}
	})
	TL3.fromTo(textBGElem.value, { opacity: 0 }, { opacity: 1, ease: 'none' }, 0)

	//TL3.fromTo(topTextElem.value, { opacity: 1 }, { opacity: 0.2, ease: 'none' }, 0)
}
function scrollingAnimationsInit() {
	const TLFrame = gsap.timeline({
		scrollTrigger: {
			trigger: thisSect.value,
			start: '+=' + -window.innerHeight / 5,
			end: '+=' + window.innerHeight,
			toggleActions: 'play none none reverse',
			invalidateOnRefresh: true,
			onToggle: (self) => {
				if (self.progress >= 1) {
				} else {
				}
			}
		}
	})

	let thisTLControlSeq = gsap.timeline({
		scrollTrigger: {
			trigger: thisSect.value,
			start: () => 'top bottom',
			end: () => 'bottom top',
			scrub: false,
			toggleActions: 'play none none reverse',
			invalidateOnRefresh: true,
			onToggle: (self) => {
				console.log(self.isActive)
				isPlayCanvas.value = self.isActive
			}
		}
	})
}
function handleLoadData() {
	console.log(`loadeddata`)
	playVideo()
}
function playVideo() {}
function handleErrorLoad() {}
</script>
<template>
	<div class="index-third-section" ref="thisSect">
		<div class="sect-holder" ref="sectHolder">
			<div class="sect-content-box">
				<div class="text-bg" ref="textBGElem">
					<span>creation</span>
				</div>
				<div class="sect-bg">
					<div class="bg-holder" ref="sequenceElem">
						<loader-sequence
							path="assets/images/static/index-sequence1"
							:is-play="isPlayCanvas"
							:play-duration="2.5"
							:start-frame="1"
							:end-frame="100"
						/>
					</div>
				</div>

				<div class="top-textbox" ref="topTextElem">
					<div class="container">
						<div class="text-wrap">
							<div class="text-holder">
								<span>creation of websites is a <b>creative flight of design thought</b> </span>
							</div>
						</div>
					</div>
				</div>
				<div class="bottom-textbox" ref="bottomTextElem">
					<div class="container">
						<div class="text-wrap">
							<div class="text-holder">
								<em>
									Our inspiration <br />
									design love
								</em>
								<span>
									<b>as a result of such a creative flight, the real masterpices are born</b>
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
