<script setup></script>

<template>
	<div class="global-work-wrapper">
		<slot />
	</div>
	<div class="last-works" style="background: yellow">
		<h2>Last works</h2>
	</div>
</template>
