import { gsap } from 'gsap'

export default {
	namespaced: true,
	state: {
		isFullVideoModalOpen: false,
		fullVideoModalSrc: '',
		cursorHover: ''
	},

	actions: {},

	mutations: {
		fullModalOpened(state, value) {
			state.isFullVideoModalOpen = value
		},
		fullModalSrc(state, value) {
			state.fullVideoModalSrc = value
		},
		cursorHover(state, value) {
			state.cursorHover = value
		}
	},

	getters: {
		fullVideoOpenModal(state) {
			return state.isFullVideoModalOpen
		},
		fullModalSrc(state) {
			return state.fullVideoModalSrc
		},
		cursorHover(state) {
			return state.cursorHover
		}
	}
}
