<script setup>
import { computed, inject, onMounted, onUnmounted } from 'vue'
import { useStore } from 'vuex'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import './AboutPage.scss'
import loaderImg from '@/components/Loader/loader-img.vue'
import LoaderSequence from '@/components/Loader/loader-sequence.vue'
import ImgLoadScroll from '@/components/ImageLoadOnScrol/img-load-scroll.vue'
import SkarallaxBlock from '@/components/skarallax/skarallax-block.vue'
import Starfield from '@/components/starfield/starfield.vue'

const store = useStore()

const isMobile = inject('isMobileCheck')
const isRetina = inject('isRetina')

gsap.registerPlugin(ScrollTrigger)
function handleTest() {
	isHighDensity()
}
function handleLoadAllPictures() {
	store.dispatch('preloader/actReadyToLoad', true)
}
const loaderAllPicsAmount = computed(() => {
	return store.getters['preloader/allPicsAmount']
})
const picsToLoad = computed(() => {
	return store.getters['preloader/picsToLoad']
})

const isRetinaComp = computed(() => {
	return isRetina
})
onMounted(() => {
	console.log(`mount aboutpage`)
	store.dispatch('preloader/actReadyToLoad', true)
})
function isHighDensity() {
	console.log(isRetina.value)
}
onUnmounted(() => {
	console.log(`unmount aboutPage`)
	store.dispatch('preloader/resetPreloader')
})
</script>
<template>
	<div class="about-page">

		<Starfield />
		<div
			class="spacer"
			style="
				height: 11000px;

				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				justify-content: center;
				align-items: center;

			"
		>
			<span>spacer</span>
		</div>


		<loader-img src="assets/images/static/1.jpg" />
		<loader-img src="assets/images/static/2.jpg" />
	</div>
</template>
