<script setup>
//Тег <IMG который в зависимости от условий, подставляет url ретина
//грузится ПО СКРОЛЛУ
import { onMounted, onUnmounted, computed, ref, inject } from 'vue'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)
import './img-load-scroll.scss'
const props = defineProps({
	src: {
		type: String,
		required: true,
		default: ''
	},
	thumb: {
		type: Boolean,
		required: false,
		default: false
	},
	retina: {
		type: Boolean,
		required: false,
		default: false
	},
	loader: {
		type: Boolean,
		required: false,
		default: false
	},
	width: {
		type: Number,
		required: false
	},
	height: {
		type: Number,
		required: false
	}
})
const isRetina = inject('isRetina')
const isLoaded = ref(false)
const imageElement = ref()
const retinaSuffix = ref('@2x')
const inView = ref(false)
const scrollTrigger = ref()
const ratio = computed(() => {
	let style = {}
	let ratio
	if (props.width && props.height) {
		ratio = (props.height / props.width) * 100
		style.paddingTop = ratio + '%'
	}
	return style
})
const urlToLoad = computed(() => {
	let url = false
	if (props.thumb && isLoaded.value === false) {
		url = new URL('/' + props.src, import.meta.url).href
		let dotIndex = url.lastIndexOf('.')
		url = url.substring(0, dotIndex) + '@thumb' + url.substring(dotIndex)
	}
	console.log(isLoaded.value)
	if (inView.value === true) {
		console.log(`gasdf`)
		url = new URL('/' + props.src, import.meta.url).href
		if (isRetina.value === true) {
			let dotIndex = url.lastIndexOf('.')
			url = url.substring(0, dotIndex) + retinaSuffix.value + url.substring(dotIndex)
		}
	}
	return url
})
function addScrollTrigger() {
	scrollTrigger.value = ScrollTrigger.create({
		trigger: imageElement.value,
		start: 'top bottom',
		end: 'bottom top',
		toggleActions: 'play none none reverse',
		scrub: false,
		invalidateOnRefresh: true,
		onToggle: (self) => {
			if (self.isActive) inView.value = true
		}
	})
}

function handleSuccessLoad() {
	if (inView.value === true) isLoaded.value = true
	console.log(`${urlToLoad.value} ${'%c'} is loaded`, 'background: #222; color: #bada55')
}
function handleErrorLoad() {
	console.log(`${urlToLoad.value} ${'%c'}is NOT loaded`, 'background: #222;; color: red')
}
onMounted(() => {
	addScrollTrigger()
})
onUnmounted(() => {
	scrollTrigger.value.kill()
})
function handleTest() {
	scrollTrigger.value.kill()
}
</script>

<template>
	<div
		class="img-load-scroll"
		ref="imageElement"
		:class="[{ withPreloader: props.loader }, { withThumb: props.thumb }, { loaded: isLoaded }]"
		:style="ratio"
	>
		<img
			v-if="urlToLoad || props.thumb"
			:src="urlToLoad"
			@error="handleErrorLoad"
			@load="handleSuccessLoad"
			:class="{ loaded: isLoaded === true }"
			class="img-responsive"
			alt=""
		/>
		<div class="loader-box" v-if="props.loader"><div class="imgLoader"></div></div>
	</div>
</template>
