<script setup>
import { onMounted, onUnmounted, computed, ref, inject } from 'vue'
import './full-video-modal.scss'
const emit = defineEmits(['close'])
import { gsap, Power2, Expo, Power1, Linear } from 'gsap'
const fullModalSrc = computed(() => store.getters['mainInterface/fullModalSrc'])
const progressFill = ref()
const videoElement = ref()
import { useStore } from 'vuex'
const store = useStore()
const props = defineProps({
	className: {
		type: String,
		required: false
	},
	frameClass: {
		type: String,
		required: false
	}
})
let fullVideoProgressTL = gsap.timeline({ paused: true })
const isVideoPaused = ref(false)
onMounted(() => {
	fullVideoProgressTL.fromTo(progressFill.value, { width: 0 }, { width: '100%' }, 0)
})
function handleLoadData() {
	videoElement.value.classList.add('loaded')
	fullVideoProgressTL.duration(videoElement.value.duration)
	videoElement.value.play()
	fullVideoProgressTL.play()
}
function handleVideoClick() {
	isVideoPaused.value = !isVideoPaused.value
	if (isVideoPaused.value === true) {
		videoElement.value.pause()
		fullVideoProgressTL.pause()
	} else {
		videoElement.value.play()
		fullVideoProgressTL.play()
	}
}
function handleVideoEnded() {
	emit('close')
}
function handleErrorLoad() {
	console.log(`errr`)
}
</script>

<template>
	<div class="full-video-modal" :class="props.className">
		<div class="modal-holder">
			<button class="bg-close-btn" type="button" @click="emit('close')"></button>
			<div class="modal-frame" :class="props.frameClass">
				<button class="global-close-btn" type="button" @click="emit('close')"><span></span></button>
				<div class="vLoader"></div>
				<video
					ref="videoElement"
					@loadeddata="handleLoadData"
					@click="handleVideoClick"
					@ended="handleVideoEnded"
					autoplay
					v-if="fullModalSrc"
					:loop="false"
					@error="handleErrorLoad"
				>
					<source :type="'video/mp4'" :src="fullModalSrc" />
					<p>Sorry, your browser does not support the &lt;video&gt; element.</p>
				</video>
				<div class="video-progress-box"><div class="progress-fill" ref="progressFill"></div></div>
			</div>
		</div>
	</div>
</template>
