<script setup>
//Тег который в зависимости от условий, подставляет url ретина
import { onMounted, computed, ref, watch, inject, nextTick, onUpdated } from 'vue'
import { useStore } from 'vuex'
const store = useStore()
const emit = defineEmits(['canvasImages'])

import { gsap, Power2, Expo, Power1, Linear } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

const props = defineProps({
	path: {
		type: String,
		required: true,
		default: ''
	},
	startFrame: {
		type: Number,
		required: false,
		default: 0
	},
	endFrame: {
		type: Number,
		required: true,
		default: 0
	},
	isPlay: {
		type: Boolean,
		required: false,
		default: false
	},
	playDuration: {
		type: Number,
		required: true,
		default: 1
	},
	extension: {
		type: String,
		required: false,
		default: 'webp'
	},
	debug: {
		type: Boolean,
		required: false
	}
})
const isLoaded = ref(false)
const imagesUrls = ref([])
const canvasImages = ref([])
let sortedCanvasImages = []
let canvas
let context
const canvasWrapElement = ref()
const canvasElement = ref()
let thisTLControlSeq = gsap.timeline({
	paused: true,
	repeat: -1
})
const frames = {
	frame: props.startFrame
}

function prepareArrayToLoad(path) {
	for (let i = props.startFrame; i <= props.endFrame; i++) {
		const imagePath = '/' + props.path + '/' + i.toString().padStart(4, '0') + '.' + props.extension
		imagesUrls.value.push(new URL(imagePath, import.meta.url).href)
		store.commit('preloader/plus')
	}
}

const loadImage = (url, uid) =>
	new Promise((resolve, reject) => {
		const img = new Image()
		img.addEventListener('load', () => resolve(img))
		img.addEventListener('error', (err) => reject(err))
		img.uid = uid
		img.src = url
	})

function collectAndLoadAllImages() {
	let imageIndex = props.startFrame
	imagesUrls.value.map((imgPath, index) => {
		console.log(imgPath, imageIndex)
		loadImage(imgPath, imageIndex)
			.then((img) => {
				if (props.debug === true) console.log(`${imgPath} ${'%c'} is loaded`, 'background: #222; color: #bada55')
				canvasImages.value.push(img)
				store.commit('preloader/minus')
			})
			.catch((err) => {
				console.log(`${imgPath} ${'%c'}is NOT loaded`, 'background: #222;; color: red')
			})
		imageIndex++
	})
}
function initCanvas() {
	canvas = canvasElement.value
	canvas.width = canvasImages.value[0].width
	canvas.height = canvasImages.value[0].height
	context = canvas.getContext('2d')

	thisTLControlSeq.fromTo(
		frames,
		{ frame: () => props.startFrame - 1 },
		{ frame: () => props.endFrame, duration: props.playDuration, onUpdate: renderToCanvas, snap: 'frame', ease: Linear.easeNone },
		0
	)
}
function renderToCanvas() {
	context.clearRect(0, 0, canvas.width, canvas.height)
	let idToRender = canvasImages.value.find((x) => x.uid === frames.frame)

	if (idToRender) {
		context.drawImage(idToRender, 0, 0)
	}
}

watch(
	() => store.state.preloader.isReadyToLoad,
	(loaded) => {
		if (loaded === true) {
			collectAndLoadAllImages()
		}
	},
	{ immediate: false }
)

watch(
	() => store.state.preloader.allResourcesLoaded,
	(loaded) => {
		if (loaded === true) {
			initCanvas()
		}
	},
	{ immediate: false }
)
onUpdated(() => {
	if (props.isPlay === true) {
		thisTLControlSeq.play()
	} else {
		thisTLControlSeq.pause()
	}
})

function handleLoadedImage() {
	isLoaded.value = true
}
function handleErrorLoadingImage() {
	console.error(`image ${imageElement.value.src} is not loaded`)
}

onMounted(() => {
	prepareArrayToLoad()
})
</script>

<template>
	<div class="canvas-wrap" ref="canvasWrapElement">
		<canvas ref="canvasElement"></canvas>
	</div>
</template>
