import { createWebHistory, createRouter } from 'vue-router'
import DefaultLayout from '@/layout/DefaultLayout.vue'
import WorkViewLayout from '@/layout/WorkViewLayout.vue'

import FrontPage from '@/views/FrontPage/FrontPage.vue'
import AboutPage from '@/views/AboutPage/AboutPage.vue'
import ErrorPage from '@/views/404/ErrorPage.vue'
import MainPage from '@/views/MainPage.vue'

import worksArray from '@/projects/projects-list.json'
const routes = [
	{
		path: '/',
		name: 'FrontPage',
		component: FrontPage,
		meta: { layout: DefaultLayout }
	},
	{
		path: '/about',
		name: 'AboutPage',
		component: AboutPage,
		meta: { layout: DefaultLayout }
	},

	{
		path: '/works',
		name: 'Works',
		component: MainPage,
		meta: { layout: DefaultLayout },
		children: []
	},
	{
		path: '/contact',
		name: 'Contacts',
		component: AboutPage,
		meta: { layout: DefaultLayout }
	}
	// {
	// 	path: "/:pathMatch(.*)*",
	// 	name: "NotFound",
	// 	component: ErrorPage,
	// 	meta: {layout: DefaultLayout},
	// },
]

worksArray.map((item, index) => {
	routes[2].children.push({
		name: item.name,
		path: '/works/' + item.slug,
		component: () => import(`@/projects/${item.slug}/${item.slug}.vue`),
		meta: { layout: WorkViewLayout }
	})
})

const router = createRouter({
	history: createWebHistory(),
	routes,
	scrollBehavior(to, from, savedPosition) {
		// always scroll to top
		return { top: 0 }
	}
})

export default router
