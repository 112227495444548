<script setup>
import './index-second-section.scss'
import { ref, nextTick, onMounted, computed, watch, onUnmounted } from 'vue'
import { useStore } from 'vuex'
const store = useStore()

import { gsap, Power2, Expo, Power1, Linear } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { CustomEase } from 'gsap/CustomEase'
import LoaderVideo from '@/components/Loader/loader-video.vue'
gsap.registerPlugin(ScrollTrigger)
gsap.registerPlugin(CustomEase)

let animationTimeLine = gsap.timeline({
	paused: true,
	repeatDelay: 2,
	repeat: 3
})
let mainAnimationTL = gsap.timeline({
	paused: true
})
let titleAnimationTL = gsap.timeline({
	paused: true
})
const thisSect = ref()
const sectHolder = ref()
const mainTextElem = ref()
const sectMainbox = ref()
const reelsFrameElem = ref()
const reelsElem = ref()
const reelsTitleElem = ref()
const bottomTextElem = ref()
const fullVideoElem = ref()
const fullVideoBoxElem = ref()
const isFullVideoLoaded = ref(-1)
const isFullVideoBoxOpen = ref(false)
const urlToLoad = ref('')
const fullVideoPlayBtn = ref()
const followerElem = ref()
const urlFullVideo = ref()
const thumbVideoPlay = ref(false)
const progressElem = ref()
const titleSpanElem = ref()
const reelsTitleBoxElem = ref()
const TLFrame = ref()
const isAllLoaded = computed(() => {
	return store.getters['preloader/isAllLoaded']
})

watch(
	() => isAllLoaded.value,
	(allLoaded) => {
		console.log(`all loaded from sect`)

		animations()
	},
	{ immediate: false }
)
onMounted(() => {
	console.log(`mounted index second section`)
	scrollingAnimationsInit()
})

function animations() {
	// фиксим секцию
	const TL = gsap.timeline({
		scrollTrigger: {
			trigger: thisSect.value,
			start: 0,
			end: 'bottom top',
			pin: sectHolder.value,
			invalidateOnRefresh: true
		}
	})

	//большой текст, снизу вверх скролл
	const TL1 = gsap.timeline({
		scrollTrigger: {
			trigger: thisSect.value,
			start: 0,
			end: '+=' + window.innerHeight * 3,
			scrub: true,
			invalidateOnRefresh: true
		}
	})
	TL1.fromTo(mainTextElem.value, { yPercent: 20 }, { yPercent: -20, ease: 'none' }, 0)
	TL1.fromTo(reelsTitleBoxElem.value, { y: '60vh' }, { y: '-10vh', ease: 'none' }, 0)

	//Фрейм рилса

	const TL22 = gsap.timeline({
		scrollTrigger: {
			trigger: thisSect.value,
			start: window.innerHeight,
			end: '+=' + window.innerHeight / 3,
			scrub: true,
			invalidateOnRefresh: true
		}
	})
	TL22.fromTo(
		reelsTitleElem.value,
		{ opacity: 0 },
		{
			opacity: 1,
			ease: Power1.easeIn
		}
	)

	//большой текст, затемнение
	const TL3 = gsap.timeline({
		scrollTrigger: {
			trigger: thisSect.value,
			start: window.innerHeight,
			end: '+=' + window.innerHeight,
			scrub: true,
			invalidateOnRefresh: true
		}
	})

	TL3.fromTo(bottomTextElem.value, { yPercent: 100 }, { yPercent: 0, ease: 'none' }, 0)

	//titleAnimationTL.fromTo(titleSpanElem.value, { opacity: 1, y: 0 }, { opacity: 0, y: -30 }, 0)

	//большой текст, затемнение
	const TLEnd1 = gsap.timeline({
		scrollTrigger: {
			trigger: thisSect.value,
			start: '+=' + window.innerHeight,
			end: '+=' + window.innerHeight,
			scrub: true,
			invalidateOnRefresh: true,
			onToggle: (self) => {
				console.log(self.progress)
				if (self.progress >= 1) {
					thumbVideoPlay.value = false
				}
				else{
					thumbVideoPlay.value = true
				}
			}
		}
	})
	TLEnd1.fromTo(sectHolder.value, { opacity: 1 }, { opacity: 0, ease: 'none' }, 0)
}
function scrollingAnimationsInit() {
	TLFrame.value = gsap.timeline({
		scrollTrigger: {
			trigger: thisSect.value,
			start: 'top 110%',
			end: '+=' + window.innerHeight,
			toggleActions: 'play none none reverse',
			invalidateOnRefresh: true,
			onToggle: (self) => {
				if (self.progress >= 1) {
					reelsFrameElem.value.classList.add('actived')
					thumbVideoPlay.value = true
				} else {
					reelsFrameElem.value.classList.remove('actived')
					thumbVideoPlay.value = false
				}
			}
		}
	})
}
function handleLoadData() {
	console.log(`loadeddata`)
	isFullVideoLoaded.value = 1
	playVideo()
}
function playVideo() {
	fullVideoElem.value.currentTime = 0
	fullVideoElem.value.play()
	isFullVideoBoxOpen.value = true
	store.commit('mainInterface/cursorHover', 'video-close')
	reelsFrameElem.value.classList.add('playing')
	let videoDuration = fullVideoElem.value.duration
	gsap.fromTo(progressElem.value, { width: 0 }, { width: '100%', duration: videoDuration, ease: 'none' })
	titleAnimationTL.seek(0).play()
}
function handleErrorLoad() {
	console.log(`${urlToLoad.value} ${'%c'}is NOT loaded`, 'background: #222;; color: red')
}
function handleFullVideoBtn() {
	isFullVideoBoxOpen.value = true
	if (isFullVideoLoaded.value === -1) {
		console.log(isFullVideoLoaded.value)
		isFullVideoLoaded.value = 0 // in loading video state
		urlFullVideo.value = 'assets/video/index-full-video.webm'
	} else {
		setTimeout(function () {
			playVideo()
		}, 50)

		console.log(`playvideo`)
	}
}
function stopVideoAndClose() {
	reelsFrameElem.value.classList.remove('playing')
	store.commit('mainInterface/cursorHover', '')
	titleAnimationTL.reverse()
	setTimeout(function () {
		isFullVideoBoxOpen.value = false
	}, 1000)
}
function handleFullVideoEnded() {
	console.log(`ended`)
	stopVideoAndClose()
}
function handleFullVideoCloseBtn() {
	console.log(`close`)
	stopVideoAndClose()
}

onUnmounted(() => {
	TLFrame.value.kill()
})
</script>
<template>
	<div class="index-second-section" ref="thisSect">
		<div class="section-mainbox" ref="sectMainbox">
			<div class="sect-holder" ref="sectHolder">
				<div class="main-text-wrap" ref="mainTextElem">
					<span class="main-text">We doing incredible things more than 17 years</span>
				</div>

				<div class="reels-box">
					<div class="reels-holder">
						<div class="reels-title-box" ref="reelsTitleBoxElem">
							<span class="reels-title" ref="reelsTitleElem">
								<span ref="titleSpanElem">view our <br /><b>showreel</b></span>
							</span>
						</div>

						<div class="reels-frame" ref="reelsFrameElem">
							<div class="reels" ref="reelsElem">
								<div class="follower" id="vidosFollower" ref="followerElem"></div>
								<button
									@mouseenter="store.commit('mainInterface/cursorHover', 'video')"
									@mouseleave="store.commit('mainInterface/cursorHover', '')"
									class="play-button"
									type="button"
									ref="fullVideoPlayBtn"
									v-show="isFullVideoBoxOpen === false"
									@click="handleFullVideoBtn"
								></button>
								<loader-video path="assets/video" file-name="works-quad" :thumbVideoPlay="thumbVideoPlay" :autoplay="false" :loop="true" />
								<div class="vLoader" :class="{ showed: isFullVideoLoaded === 0 }"></div>
								<div class="full-video-box" ref="fullVideoBoxElem" v-if="isFullVideoBoxOpen === true">
									<video ref="fullVideoElem" @loadeddata="handleLoadData" muted @ended="handleFullVideoEnded" @error="handleErrorLoad">
										<source type="video/webm" :src="urlFullVideo" />
										<p>Sorry, your browser does not support the &lt;video&gt; element.</p>
									</video>
									<div class="video-progress-box">
										<div class="progress-fill" ref="progressElem"></div>
									</div>
									<button
										class="close-button"
										type="button"
										ref="fullVideoCloseBtn"
										@mouseenter="store.commit('mainInterface/cursorHover', 'video-close')"
										@mouseleave="store.commit('mainInterface/cursorHover', '')"
										@click="handleFullVideoCloseBtn"
									></button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="bottom-textbox">
					<div class="container">
						<div class="bottom-row" ref="bottomTextElem">
							<span class="text1">We innovate <br />and creativity</span>
							<span class="text2">into your <br />project</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
