import { createApp } from 'vue'
import { i18n } from '@/i18n'

import App from './App.vue'
import router from './router'
import store from './store/store'
import mitt from 'mitt'
export const emitter = mitt()
import { isMobileChecker } from '@/helpers/isMobileChecker'
import { isRetinaChecker } from '@/helpers/isRetina'

import './scss/global-styles.scss'
import '@/assets/fonts/fonts.scss'
import './scss/main.scss'

const app = createApp(App)

app
	.use(store)
	.use(router)
	.use(i18n)
	.provide('emitter', emitter)
	.provide('isMobileCheck', isMobileChecker())
	.provide('isRetina', isRetinaChecker())
	.mount('#app')
