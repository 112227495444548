<script setup>
import { onMounted, onUnmounted, computed, ref, inject, watch, nextTick } from 'vue'
import { gsap, Power2, Expo, Power1 } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import './bottom-works.scss'
import worksArray from '@/projects/projects-list.json'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import LoaderPic from '@/components/Loader/loader-pic.vue'
const store = useStore()
const route = useRoute()
const workSlug = computed(() => route.meta.slug)
const curentWorkIndex = computed(() => {
	return worksArray.findIndex(({ slug }) => slug === workSlug.value)
})
const nextWork = computed(() => {
	//если последняя работа значит следующая это первая
	return curentWorkIndex.value >= worksArray.length-1 ? worksArray[0] : worksArray[curentWorkIndex.value+1]
})
const prevWork = computed(() => {
	//если последняя работа значит следующая это первая
	return curentWorkIndex.value <= 0 ? worksArray[worksArray.length-1] : worksArray[curentWorkIndex.value-1]
})
const nextWorkPosterPath = `portfolio/${nextWork.value.slug}/images/_work-prev-next-full.jpg`
const nextWorkThumbPath = `portfolio/${nextWork.value.slug}/images/_work-prev-next-thumb.webp`
const prevWorkThumbPath = `portfolio/${prevWork.value.slug}/images/_work-prev-next-thumb.webp`

onMounted(() => {

})
onUnmounted(() => {})
</script>

<template>
	<div class="bottom-works-section">
		<div class="sect-holder">
			<div class="next-work-bg">
				<loader-pic :src="nextWorkPosterPath" />
			</div>
			<div class="sect-content">
				<div class="container">
					<div class="prev-next-row">
						<div class="prev-work">
							<div class="work-box">
								<a :href="'/works/'+ prevWork.slug" class="work-thumb-pic"  @mouseenter="store.commit('mainInterface/cursorHover', 'discover')" @mouseleave="store.commit('mainInterface/cursorHover', '')">
									<loader-pic :src="prevWorkThumbPath" />
									<span>previous</span>
								</a>
								<div class="work-name-box">
									<span class="work-title">{{prevWork.name}}</span>
									<span class="work-subtitle">{{prevWork.subname}}</span>
								</div>
							</div>
						</div>
						<div class="next-work">
							<div class="work-box">
								<a :href="'/works/'+ nextWork.slug" class="work-thumb-pic"  @mouseenter="store.commit('mainInterface/cursorHover', 'discover')" @mouseleave="store.commit('mainInterface/cursorHover', '')">
									<loader-pic :src="nextWorkThumbPath" />
									<span>next</span>
								</a>
								<div class="work-name-box">
									<span class="work-title">{{nextWork.name}}</span>
									<span class="work-subtitle">{{nextWork.subname}}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>
