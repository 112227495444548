<script setup>
//Тег <IMG который в зависимости от условий, подставляет url ретина
import { onMounted, onUnmounted, nextTick, computed, ref, inject, watch } from 'vue'
import { useStore } from 'vuex'
const store = useStore()
import { gsap, Linear } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)
const props = defineProps({
	y: {
		type: Number,
		required: false,
		default: 0
	},
	yEndAdd: {
		type: Number,
		required: false,
		default: 0
	},

	yFrom: {
		type: Number,
		required: false,
		default: 0
	},
	x: {
		type: Number,
		required: false
	},
	xFrom: {
		type: Number,
		required: false,
		default: 0
	},
	scaleFrom: {
		type: Number,
		required: false,
		default: 1
	},
	scaleTo: {
		type: Number,
		required: false,
		default: 1
	},
	insideClass: {
		type: String,
		required: false
	},
	waitLoading: {
		type: Boolean,
		required: false,
		default: false
	},
	percent: {
		type: Boolean,
		required: false,
		default: false
	}
})
const thisElement = ref()
const animBlock = ref()
const isAllLoaded = computed(() => store.getters['preloader/isAllLoaded'])

let thisTL
function initAnimation() {
	let endAdd = props.y + props.yEndAdd
	if (props.percent === true) {
		//если проценты , то нужно прибавить к границе окончания эффекта
		//процент от блока, который мы тянем, в зависимости от высоты блока
		//расчитываем, сколько пикселей нужно прибавить исходя из процентов высоты
		const elementHeight = thisElement.value.getBoundingClientRect().height
		endAdd = (elementHeight / 100) * props.y
	}
	thisTL = gsap.timeline({
		scrollTrigger: {
			trigger: thisElement.value,
			start: 'top bottom',
			end: () => 'bottom top-=' + endAdd,
			scrub: true,
			invalidateOnRefresh: true
		}
	})
	if (props.percent === true) {
		thisTL.fromTo(
			animBlock.value,
			{ yPercent: props.yFrom, scale: props.scaleFrom },
			{ yPercent: () => props.y, scale: props.scaleTo, ease: Linear.easeNone }
		)
	} else {
		thisTL.fromTo(animBlock.value, { y: props.yFrom, scale: props.scaleFrom }, { y: () => props.y, scale: props.scaleTo, ease: Linear.easeNone })
	}
}
watch(
	() => isAllLoaded.value,
	(allLoaded) => {
		if (props.waitLoading && allLoaded) {
			initAnimation()
		}
	},
	{ immediate: false }
)
onMounted(() => {
	if (props.waitLoading === false) {
		initAnimation()
	}
})
onUnmounted(() => {
	thisTL.kill()
})
</script>

<template>
	<div class="skarallax" ref="thisElement">
		<div class="skarallax-animation" ref="animBlock" :class="props.insideClass">
			<slot></slot>
		</div>
	</div>
</template>
