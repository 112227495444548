<script setup>
import { onMounted, onUnmounted, computed, ref, inject, watch } from 'vue'
import { gsap, Expo } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)
import './main-menu.scss'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
const store = useStore()
const isRetina = inject('isRetina')
const isLoaded = ref(false)
const menuElem = ref()
const route = useRoute()
const isMenuOpened = ref(false)
const isWorkView = computed(() => {
	if(route.matched[0]){
		return route.matched[0].name === 'Works'
	}
})
let menuTriggerSequence
let menuSequence = gsap.timeline({ paused: true })
onMounted(() => {
	console.log(`menn`)

	setTimeout(function () {
		menuTriggerSequence = gsap.timeline({
			scrollTrigger: {
				start: window.innerHeight,

				invalidateOnRefresh: true,
				toggleActions: 'play none none reverse',
				scrub: false,

				onToggle: (self) => {
					console.log(self.progress)
					if (self.progress > 0) {
						menuSequence.play()
					} else {
						isMenuOpened.value = false
						menuSequence.reverse()
					}
				}
			}
		})
	}, 400)

	menuSequence.fromTo(menuElem.value, { yPercent: 100 }, { yPercent: 0, ease: Expo.easeOut }, 0)
})
onUnmounted(() => {
	menuTriggerSequence.kill()
	menuSequence.kill()
})
watch(
	() => isMenuOpened.value,
	(isOpened) => {
		if (isOpened) {
			window.addEventListener('scroll', function () {
				isMenuOpened.value = false
			})
		}
	},
	{ immediate: false }
)
function handleMenuBtnClick() {
	isMenuOpened.value = !isMenuOpened.value
	console.log(isMenuOpened.value)
}
</script>

<template>
	<div class="menu-box" :class="[{ opened: isMenuOpened },{ inWork: isWorkView }]" ref="menuElem">
		<button class="close-btn" type="button" @click="isMenuOpened = false"></button>
		<div class="menu-ovh-wrap">
			<div class="drop">
				<ul class="main-nav">
					<li><a href="#">works</a></li>
					<li><a href="#">about us</a></li>
					<li><a href="#">services</a></li>
				</ul>
			</div>
			<button class="menu-btn" type="button"
					@click="handleMenuBtnClick"

			>
				<span>
					<i><b>menu</b><em>close</em></i>
				</span>
			</button>
		</div>
	</div>
</template>
