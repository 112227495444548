<script setup>
import { onMounted, onUnmounted, computed, ref, inject, watch, nextTick } from 'vue'
import { gsap } from 'gsap'
import './circle-follower.scss'
const jellyRef = ref()
const textRef = ref()
const pos = { x: 0, y: 0 }
const vel = { x: 0, y: 0 }
const set = {}
import { useStore } from 'vuex'
const store = useStore()
const cursorHover = computed(() => store.getters['mainInterface/cursorHover'])

onMounted(() => {
	set.x = gsap.quickSetter(jellyRef.value, 'x', 'px')
	set.y = gsap.quickSetter(jellyRef.value, 'y', 'px')
	set.r = gsap.quickSetter(jellyRef.value, 'rotate', 'deg')
	set.sx = gsap.quickSetter(jellyRef.value, 'scaleX')
	set.sy = gsap.quickSetter(jellyRef.value, 'scaleY')
	set.rt = gsap.quickSetter(textRef.value, 'rotate', 'deg')

	window.addEventListener('mousemove', setFromEvent)
})
onUnmounted(() => {
	window.removeEventListener('mousemove', setFromEvent)
})

// Function for Mouse Move Scale Change
function getScale(diffX, diffY) {
	const distance = Math.sqrt(Math.pow(diffX, 2) + Math.pow(diffY, 2))
	return Math.min(distance / 800, 0.3)
}

// Function For Mouse Movement Angle in Degrees
function getAngle(diffX, diffY) {
	return (Math.atan2(diffY, diffX) * 180) / Math.PI
}

// Start Animation loop
const loop = () => {
	// Calculate angle and scale based on velocity
	var rotation = getAngle(vel.x, vel.y)
	var scale = getScale(vel.x, vel.y)

	set.x(pos.x)
	set.y(pos.y)
	set.r(rotation)
	set.sx(1 + scale)
	set.sy(1 - scale)
	set.rt(-rotation)
}
// Caluclate Everything Function
const setFromEvent = (e) => {
	// Mouse X and Y
	const x = e.clientX
	const y = e.clientY

	// Animate Pos Object and calculate Vel Object Velocity
	gsap.to(pos, {
		x: x,
		y: y,
		duration: 0.5,
		onUpdate: () => {
			vel.x = x - pos.x
			vel.y = y - pos.y
		}
	})

	loop()
}
function useTicker(callback, paused) {
	if (!paused && callback) {
		gsap.ticker.add(callback)
	}
	return () => {
		gsap.ticker.remove(callback)
	}
}
useTicker(loop)
</script>

<template>
	<div class="jelly-blob debug" :class="cursorHover" ref="jellyRef">
		<div class="point"></div>
		<div class="text-cursors" ref="textRef">
			<div class="video-close"><span></span></div>
			<div class="fullscreen-play">fullscreen</div>
			<div class="discover">Discover</div>
		</div>
	</div>
</template>
