<script setup>
//Тег <IMG который в зависимости от условий, подставляет url ретина
import { onMounted, computed, ref, inject } from 'vue'
import { useStore } from 'vuex'
const store = useStore()
const props = defineProps({
	src: {
		type: String,
		required: true,
		default: ''
	},
	width: {
		type: Number,
		required: false
	},
	height: {
		type: Number,
		required: false
	},
	debug: {
		type: Boolean,
		required: false
	},
	retina: {
		type: Boolean,
		default: false,
		required: false
	}
})
const isRetina = inject('isRetina')
const isLoaded = ref(false)
const imageElement = ref()
const retinaSuffix = ref('@2x')
const ratio = computed(() => {
	let style = {}
	let ratio
	if (props.width && props.height) {
		ratio = (props.height / props.width) * 100
		style.paddingTop = ratio + '%'
	}
	return style
})
const urlToLoad = computed(() => {
	let url = false
	if (store.state.preloader.isReadyToLoad === true) {
		url = new URL('/' + props.src, import.meta.url).href
		if (isRetina.value === true && props.retina === true) {
			let dotIndex = url.lastIndexOf('.')
			url = url.substring(0, dotIndex) + retinaSuffix.value + url.substring(dotIndex)
		}
	}
	return url
})
function addToPreloader() {
	store.commit('preloader/plus')
}

function handleSuccessLoad() {
	isLoaded.value = true
	store.commit('preloader/minus')
	if (props.debug === true) console.log(`${urlToLoad.value} ${'%c'} is loaded`, 'background: #222; color: #bada55')
}
function handleErrorLoad() {
	console.error(`${urlToLoad.value} ${'%c'}is NOT loaded`, 'background: #222;; color: red')
}
onMounted(() => {
	addToPreloader()
})
</script>

<template>
	<div class="img" :style="ratio" :class="[{ withRatio: props.width }, { loaded: isLoaded }]">
		<img v-if="urlToLoad" ref="imageElement" :src="urlToLoad" @error="handleErrorLoad" @load="handleSuccessLoad" class="img-responsive" alt="" />
	</div>
</template>
<style scoped lang="scss">
.image {
	&.withRatio {
		img {
			@include full-height-abs;
			display: block;
			object-fit: cover;
		}
	}
}
</style>
