<script setup>
import './Preloader.scss'
import { computed, inject, ref, onMounted, watch } from 'vue'
import { useStore } from 'vuex'
const store = useStore()
import smoothScrollbar from 'smooth-scrollbar'
import { Expo, gsap, Power1, Power3 } from 'gsap'
import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router'
import Follower from '@/layout/Preloader/Follower.vue'
const router = useRouter()
const route = useRoute()
let animationTLIn = gsap.timeline({
	paused: true
})
let loaderNumbersTL = gsap.timeline({
	paused: true
})
let animationTLOut = gsap.timeline({
	paused: true
})
let animationTLShortLoader = gsap.timeline({
	paused: true
})
let animationTLShortLoaderOut = gsap.timeline({
	paused: true
})
const loaderAllPicsAmount = computed(() => {
	return store.getters['preloader/allPicsAmount']
})
const picsToLoad = computed(() => {
	return store.getters['preloader/picsToLoad']
})
const isGoNextPage = computed(() => store.getters['preloader/goNextPage'])
const scrollbar = computed(() => store.getters['preloader/scrollbarInstance'])
const loadPath = computed(() => store.getters['preloader/loadPath'])
const percentsLoaded = computed(() => {
	let percent = store.getters['preloader/percentLoaded']
	//console.log(percent)
	return store.getters['preloader/percentLoaded']
})
const isGoingNextPage = ref(false)
const isAllLoaded = computed(() => store.getters['preloader/isAllLoaded'])
const isMountedPage = computed(() => store.getters['preloader/isMountedPage'])
const cachedPaths = computed(() => store.getters['preloader/cachedPaths'])
let scrollbarInstance
const isPreloaderIn = ref(false)
const isPreloaderOut = ref(false)
const currentRoute = ref()
const preloaderElem = ref()
const numbersElem = ref()
const frameElem = ref()
const isFollowerLaunch = ref(false)
const number1Elem = ref()
const number2Elem = ref()
const isShortLoaderShow = ref(false)
const shortPreloaderElem = ref()
const shortHolderElem = ref()
const shortFrameElem = ref()

onMounted(() => {
	console.log(`mounted preloader`)

	setTimeout(function () {
		currentRoute.value = route.path
	}, 50)
	animationTLIn.to(
		preloaderElem.value,
		{
			autoAlpha: 0,
			duration: 1,
			ease: Power3.easeInOut
		},
		0
	)
	animationTLOut.fromTo(
		frameElem.value,
		{
			height: '100vh'
		},
		{
			duration: 1,
			height: '0vh',
			ease: Power3.easeInOut,
			onComplete: function () {
				if (isGoNextPage.value === true) {
					animationTLIn.reversed(false)
					animationTLIn.time(1)
				} else {
					store.commit('preloader/goNextPage', true)
					isGoingNextPage.value = true
					animationTLIn.time(1)
				}
			}
		},
		0
	)
	animationTLShortLoaderOut.fromTo(
		shortPreloaderElem.value,
		{
			yPercent: 0
		},
		{
			duration: 0.5,
			yPercent: -100,
			ease: Power3.easeOut,
			onComplete: function () {
				isShortLoaderShow.value = false
				animationTLShortLoaderOut.revert()
				animationTLShortLoader.revert()
				console.log(`compl outttt`)
			}
		},
		0
	)

	animationTLShortLoader.fromTo(
		shortHolderElem.value,
		{
			opacity: 0
		},
		{
			duration: 0.5,
			opacity: 1,
			ease: Power3.easeIn,
			onComplete: function () {}
		},
		0
	)
})

watch(
	() => isPreloaderOut.value,
	(isOut) => {
		if (isOut === true) {
			console.log(`preloader out animation End`)

			isPreloaderOut.value = false
		}
	},
	{ immediate: false }
)
watch(
	() => isAllLoaded.value,
	(loaded) => {
		console.log('loaded:' + loaded)
		if (loaded === true) {
			launch()
		}
	},
	{ immediate: false }
)
watch(
	() => isMountedPage.value,
	(isMountedPage) => {
		console.log(`mounted is: ${isMountedPage}`)
		if (isMountedPage === true) {
			if (isGoNextPage.value === false) {
				store.commit('preloader/readyToLoad', true)
			} else {
				console.log(`elas`)
			}
		}
	},
	{ immediate: false }
)

router.beforeEach((to, from, next) => {
	isGoingNextPage.value = false
	console.log(cachedPaths.value)
	if (from.name === undefined) {
		next()
		if (!cachedPaths.value.includes(to.name)) {
			store.commit('preloader/addCachedPath', to.name)
		}

		console.log(`open from ZERO`)
	} else {
		if (!(route.path === loadPath.value)) {
			console.log(`hi`)
			store.commit('preloader/flushLoader')
			if (cachedPaths.value.includes(to.name)) {
				console.log(`short load`)
				isShortLoaderShow.value = true
				animationTLShortLoader.seek(0).play()

				animationTLShortLoader.eventCallback('onComplete', function () {
					console.log(`onCompl`)
					scrollbar.value.setPosition(0, 0)

					if (isGoingNextPage.value === false) {
						next()

						setTimeout(function () {
							animationTLShortLoaderOut.seek(0).play()
							gsap.fromTo(document.getElementsByClassName('main-page-holder'), { y: 200 }, { y: 0, duration: 0.5, ease: Power3.easeOut })
						}, 300)
					}
				})
			} else {
				animationTLOut.revert()
				animationTLIn.seek(1)
				animationTLIn.reverse(1)
			}

			animationTLIn.eventCallback('onReverseComplete', function () {
				console.log(`onReverseCompl`)
				scrollbar.value.setPosition(0, 0)
				store.commit('preloader/flushLoader')
				if (isGoingNextPage.value === false) {
					next()
					if (!cachedPaths.value.includes(to.name)) {
						store.commit('preloader/addCachedPath', to.name)
					}
				}
			})
		} else {
			console.log(`already loaded, do nothing`)

			//same route (just launch same page but from beginning) and not load anything
		}
	}
})

function updateLoader(percentsLoaded) {
	let percentsText = String(percentsLoaded)
	let firstNumber = parseInt(percentsText.charAt(0))
	let secondNumber = parseInt(percentsText.charAt(1))
	if (percentsLoaded < 10) {
		firstNumber = 0
		secondNumber = parseInt(percentsText.charAt(0))
	}
	if (percentsLoaded >= 100) {
		firstNumber = 9
		secondNumber = 9
	}
	loaderNumber1.style.transform = 'translateY(' + -firstNumber * 10 + '%)'
	loaderNumber2.style.transform = 'translateY(' + -secondNumber * 10 + '%)'
}

function launch() {
	console.log(`launch`)
	isFollowerLaunch.value = true
	animationTLOut.seek(0).play()
}
</script>

<template>
	<div class="short-page-preloader" v-show="isShortLoaderShow === true" ref="shortPreloaderElem">
		<div class="short-loader-holder" ref="shortHolderElem">
			<div class="short-frame" ref="shortFrameElem">
				<div class="short-subframe">
					<h1>asdfasdf</h1>
				</div>
			</div>
		</div>
	</div>
	<div class="page-preloader" ref="preloaderElem">
		<div class="holder">
			<div class="frame" ref="frameElem">
				<follower v-if="isFollowerLaunch" />
				<div class="subframe">
					<div class="numbers-box" ref="numbersElem">
						<span>loading {{ isAllLoaded }}</span>
						<div class="loader-number-box">
							<div class="progress-number" ref="number1Elem"><i>0</i><i>1</i><i>2</i><i>3</i><i>4</i><i>5</i><i>6</i><i>7</i><i>8</i><i>9</i></div>
							<div class="progress-number" ref="number2Elem"><i>0</i><i>1</i><i>2</i><i>3</i><i>4</i><i>5</i><i>6</i><i>7</i><i>8</i><i>9</i></div>
						</div>
						<b class="progress-numbers">{{ ('0' + percentsLoaded).slice(-2) }}</b>
						<strong>
							Loading <em style="color: red">{{ picsToLoad }}</em> from
							<i style="font-style: normal; color: green">{{ loaderAllPicsAmount }}</i>
						</strong>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
