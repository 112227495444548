<script setup>
import './OurServices.scss'
import { ref, nextTick, onMounted, computed, watch, onUnmounted, onBeforeUnmount } from 'vue'
import { useStore } from 'vuex'
const store = useStore()

import { gsap, Power2, Expo, Power1, Linear } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import LoaderVideo from '@/components/Loader/loader-video.vue'
import LoaderImg from '@/components/Loader/loader-img.vue'
import { CustomEase } from 'gsap/CustomEase'
import LoaderSequence from '@/components/Loader/loader-sequence.vue'
import CircleFollower from '@/components/circleFollower/circle-follower.vue'
import Follower from '@/layout/Preloader/Follower.vue'
import LoaderPic from '@/components/Loader/loader-pic.vue'
gsap.registerPlugin(ScrollTrigger)

let animationTimeLine = gsap.timeline({
	paused: true,
	repeatDelay: 2,
	repeat: 3
})
let mainAnimationTL = gsap.timeline({
	paused: true
})
let titleAnimationTL = gsap.timeline({
	paused: true
})
const thisSect = ref()
const sectHolder = ref()
const topTextElem = ref()
const bottomTextElem = ref()

const serviceNavElem = ref()
const followerQuad = ref()
const contentElem = ref()
const isFollowerActive = ref(false)
let xTo, yTo
const isAllLoaded = computed(() => {
	return store.getters['preloader/isAllLoaded']
})
const activedElem = ref(3)
watch(
	() => isAllLoaded.value,
	(allLoaded) => {
		console.log(`all loaded from sect`)

		animations()
	},
	{ immediate: false }
)
onMounted(() => {
	console.log(`mounted index services`)
	scrollingAnimationsInit()
})
onBeforeUnmount(() => {
	serviceNavElem.value.removeEventListener('mousemove', serviceMouseMove)
	serviceNavElem.value.removeEventListener('mouseleave', serviceMouseLeave)
})

function animations() {
	// фиксим секцию
	const TL = gsap.timeline({
		scrollTrigger: {
			trigger: thisSect.value,
			start: 'top bottom',
			end: 'bottom top',
			pin: sectHolder.value,
			invalidateOnRefresh: true
		}
	})

	// const TLTopText2 = gsap.timeline({
	// 	scrollTrigger: {
	// 		trigger: thisSect.value,
	// 		start: 'top 50%',
	// 		end: '+=' + window.innerHeight / 4,
	// 		scrub: false,
	// 		toggleActions: 'play none none reverse',
	// 		invalidateOnRefresh: true
	// 	}
	// })
	// TLTopText2.fromTo(contentElem.value, { autoAlpha: 0 }, { duration: 0.5, autoAlpha: 1, ease: 'none' }, 0)
	// TLTopText2.fromTo(bottomTextElem.value, { opacity: 0 }, { opacity: 1, ease: 'none' }, 0.6)
}
function scrollingAnimationsInit() {
	xTo = gsap.quickTo(followerQuad.value, 'x', { delay: 0.3, ease: 'power3' })
	yTo = gsap.quickTo(followerQuad.value, 'y', { ease: 'power3' })
	serviceNavElem.value.addEventListener('mousemove', function (e) {
		serviceMouseMove(e)
	})
	serviceNavElem.value.addEventListener('mouseleave', function (e) {
		serviceMouseLeave(e)
	})

	let boundingRect = serviceNavElem.value.getBoundingClientRect()
	console.log(boundingRect.top)

	let y = 70
	yTo(y)
}
function serviceMouseMove(e) {
	let boundingRect = serviceNavElem.value.getBoundingClientRect()
	let relX = e.pageX - boundingRect.left
	let relY = e.pageY - boundingRect.top
	let scrollTop = window.pageYOffset || document.documentElement.scrollTop
	let x = relX - boundingRect.width / 2
	let y = relY - boundingRect.height / 2 - scrollTop
	xTo(x)
	yTo(y)
	isFollowerActive.value = true
}
function serviceMouseLeave() {
	let boundingRect = serviceNavElem.value.getBoundingClientRect()
	let relY = boundingRect.top
	let y = relY - boundingRect.height / 4
	activedElem.value = 3
	xTo(0)
	yTo(y)
	isFollowerActive.value = false
}
function handleMouseEnter(activedNumber) {
	activedElem.value = activedNumber
}
</script>
<template>
	<div class="service-tizer-section" ref="thisSect">
		<div class="sect-holder" ref="sectHolder">
			<div class="sect-content-box" ref="contentElem">
				<div class="container">
					<div class="navi-box">
						<span class="top-title">What we do</span>
						<div class="follower-quad" ref="followerQuad" :class="{ active: isFollowerActive }">
							<div class="follower-holder">
								<div class="text-wrap">
									<span class="text-item" :class="{ active: activedElem === 1 }">Design creation</span>
									<span class="text-item" :class="{ active: activedElem === 2 }">Branding creation</span>
									<span class="text-item" :class="{ active: activedElem === 3 }">Site development</span>
									<span class="text-item" :class="{ active: activedElem === 4 }">Motion 3d objects</span>
								</div>
								<div class="pic">
									<loader-video  src="assets/video/service-video02.mp4" :autoplay="true" :loop="true" />
								</div>
							</div>
						</div>
						<div class="service-nav">
							<div class="item">
								<div class="item-holder"><span>Design</span></div>
							</div>
							<div class="item">
								<div class="item-holder"><span>Branding</span></div>
							</div>
							<div class="item">
								<div class="item-holder"><span>Sites</span></div>
							</div>
							<div class="item">
								<div class="item-holder"><span>3D Motion</span></div>
							</div>
						</div>
						<div class="service-nav duplicated" ref="serviceNavElem">
							<div class="item" :class="{ active: activedElem === 1 }" @mouseenter="handleMouseEnter(1)">
								<div class="item-holder"><span>Design</span></div>
							</div>
							<div class="item" :class="{ active: activedElem === 2 }" @mouseenter="handleMouseEnter(2)">
								<div class="item-holder"><span>Branding</span></div>
							</div>
							<div class="item" :class="{ active: activedElem === 3 }" @mouseenter="handleMouseEnter(3)">
								<div class="item-holder"><span>Sites</span></div>
							</div>
							<div class="item" :class="{ active: activedElem === 4 }" @mouseenter="handleMouseEnter(4)">
								<div class="item-holder"><span>3D Motion</span></div>
							</div>
						</div>
					</div>
				</div>
				<div class="bottom-text-wrap">
					<div class="container">
						<div class="bottom-left-text" ref="bottomTextelem">
							<span>our <br />services</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
