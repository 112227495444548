<script setup>
import { computed, inject, onMounted, onUnmounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { gsap, Power2, Expo, Power1, Linear } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import './FrontPage.scss'
//my components
import loaderImg from '@/components/Loader/loader-img.vue'
import LoaderSequence from '@/components/Loader/loader-sequence.vue'
import ImgLoadScroll from '@/components/ImageLoadOnScrol/img-load-scroll.vue'
import SkarallaxBlock from '@/components/skarallax/skarallax-block.vue'
//sections
import IndexFirstSection from '@/views/FrontPage/index-first-section.vue'
import IndexSecondSection from '@/views/FrontPage/index-second-section.vue'
import IndexThirdSection from '@/views/FrontPage/index-third-section.vue'
import Starfield from '@/components/starfield/starfield.vue'
import WorksSection from '@/views/FrontPage/WorksSection/WorksSection.vue'
gsap.registerPlugin(ScrollTrigger)
//variables
const emit = defineEmits(['mount'])
const store = useStore()
const isAllLoaded = computed(() => {
	return store.getters['preloader/isAllLoaded']
})

const fixboxElem = ref()
const thisSect = ref()
const thirdTriggerelem = ref()
const worksTriggerelem = ref()
const creationTextElem = ref()
let TLTopText1

onMounted(() => {

})
onUnmounted(() => {})

watch(
	() => isAllLoaded.value,
	(allLoaded) => {
		animations()
	},
	{ immediate: false }
)
function animations() {
	// фиксим секцию
	const TL = gsap.timeline({
		scrollTrigger: {
			trigger: thisSect.value,
			start: 'top bottom',
			end: 'bottom top',
			pin: fixboxElem.value,
			invalidateOnRefresh: true
		}
	})

	TLTopText1 = gsap.timeline({
		scrollTrigger: {
			trigger: thirdTriggerelem.value,
			start: 'top bottom',
			end: '+='+ window.innerHeight*5,
			scrub: true,
			invalidateOnRefresh: true
		}
	})
	TLTopText1.fromTo(
		creationTextElem.value,
		{ yPercent: 50 },
		{
			yPercent: -80,
			ease: 'none'
		},
		0
	)
	const TLTopText2 = gsap.timeline({
		scrollTrigger: {
			trigger: thirdTriggerelem.value,
			start: 'top bottom',
			end: '+=' + window.innerHeight / 4,
			scrub: true,
			invalidateOnRefresh: true
		}
	})
	TLTopText2.fromTo(creationTextElem.value, { opacity: 0 }, { opacity: 1, ease: 'none' }, 0)
}
</script>
<template>
	<div class="twoSectsWrap">
		<Starfield />
		<index-second-section />
		<div class="creation-works-sects" ref="thisSect">
			<div class="third-section-trigger" ref="thirdTriggerelem"></div>
			<div class="works-section-trigger" ref="worksTriggerelem"></div>
			<div class="sects-fixbox" ref="fixboxElem">
				<div class="sects-fix-holder">
					<div class="creation-text-bg" ref="creationTextElem">
						<span>creation</span>
					</div>
					<index-third-section :trigger-elem="thirdTriggerelem"/>
					<works-section :trigger-elem="worksTriggerelem"/>
				</div>

			</div>

		</div>

	</div>
</template>
