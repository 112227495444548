export default {
	namespaced: true,
	state: {
		allResourcesLoaded: false,
		isReadyToLoad: false,
		isMountedPage: false,
		isLoadedPictures: false,
		allPicsToLoadAmount: 0,
		picsToLoad: 0,
		percentLoaded: 0,
		scrollbarInstance: null,
		goNextPage: false,
		loadPath: '',
		cachedPaths: []
	},

	actions: {
		loadAllPictures({ commit }, status) {
			commit('makeLoadAllPictures', true)
		},
		actReadyToLoad({ commit }, value) {
			commit('readyToLoad', value)
		},
		resetPreloader({ commit }) {
			commit('flushLoader')
		}
	},

	mutations: {
		plus(state, url) {
			state.picsToLoad++
			state.allPicsToLoadAmount++
		},
		minus(state, url) {
			state.picsToLoad--
			state.allResourcesLoaded = state.picsToLoad <= 0
		},
		flushLoader(state, url) {
			state.allPicsToLoadAmount = 0
			state.allResourcesLoaded = false
			state.picsToLoad = 0
			state.isReadyToLoad = false
			state.percentLoaded = 0
		},
		makeLoadAllPictures(state, value) {
			state.isLoadedPictures = value
		},
		readyToLoad(state, value) {
			state.isReadyToLoad = value
		},
		goNextPage(state, value) {
			state.goNextPage = value
		},
		loadPath(state, value) {
			state.loadPath = value
		},
		isAllLoaded(state, value) {
			state.allResourcesLoaded = value
		},
		scrollbarInstance(state, value) {
			state.scrollbarInstance = value
		},
		isMountedPage(state, value) {
			state.isMountedPage = value
		},
		addCachedPath(state, value) {
			state.cachedPaths.push(value)
		}
	},

	getters: {
		picsToLoad(state) {
			return state.picsToLoad
		},
		allPicsAmount(state) {
			return state.allPicsToLoadAmount
		},
		isAllLoaded(state) {
			return state.allResourcesLoaded
		},
		percentLoaded(state) {
			let remainPercentsToLoad = 0
			if (state.allPicsToLoadAmount > 0) {
				remainPercentsToLoad = parseInt((100 / Number(state.allPicsToLoadAmount)) * Number(state.picsToLoad))
			}
			return remainPercentsToLoad
		},
		goNextPage(state) {
			return state.goNextPage
		},
		loadPath(state) {
			return state.loadPath
		},
		scrollbarInstance(state) {
			return state.scrollbarInstance
		},
		isMountedPage(state) {
			return state.isMountedPage
		},
		cachedPaths(state) {
			return state.cachedPaths
		}
	}
}
