<script setup>
import { onMounted, onUnmounted, computed, ref, inject, watch, nextTick } from 'vue'
import { gsap, Power2, Expo, Power1, Linear } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import './works-contacts.scss'
import worksArray from '@/projects/projects-list.json'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import LoaderPic from '@/components/Loader/loader-pic.vue'
import smoothScrollbar from 'smooth-scrollbar'
const store = useStore()

const scrollTopBtnElem = ref()
const sectElem = ref()
const sectHolderElem = ref()
let mainTL
let scrollBarInstance
const isAllLoaded = computed(() => store.getters['preloader/isAllLoaded'])
onMounted(() => {
	scrollBarInstance = smoothScrollbar.getAll()[0]
})
onUnmounted(() => {})

watch(
	() => isAllLoaded.value,
	(allLoaded) => {
		animations()
	},
	{ immediate: false }
)
function handleScrollTopBtnClick() {
	scrollBarInstance.scrollTo(0, 0, 500)
}
function animations() {
	mainTL = gsap.timeline({
		scrollTrigger: {
			trigger: sectElem.value,
			start: 'top bottom',
			end: 'bottom bottom',
			scrub: true,
			invalidateOnRefresh: true
		}
	})
	mainTL.fromTo(
		sectHolderElem.value,
		{  y: '-100vh' },
		{  y: 0, ease: Linear.easeNone },
		0
	)





}
</script>

<template>
	<div class="works-contacts-section"  ref="sectElem">
		<div class="sect-holder" ref="sectHolderElem">
			<div class="sect-bg">
				<loader-pic src="assets/images/static/works-contacts-section-bg.jpg" />
			</div>
			<div class="sect-content">
				<div class="container">
					<div class="container-holder">
						<div class="telegram-textbox">
							<span class="tg-text"><span>our</span> <strong>telegram</strong></span>
						</div>
						<a class="tg-link" href="#"><i><img src="/assets/images/vector/soc-telegram.svg" alt=""></i></a>
						<button class="scroll-bottom-btn js_scrollToSecondScreenBtn"  type="button" ref="scrollTopBtnElem" @click="handleScrollTopBtnClick">
							<i><img src="@/assets/images/vector/arrow-down.svg" alt=""></i>
							<span></span>
						</button>
						<div class="footer-box">
							<div class="left-col">
								<span class="copy"><strong>©bproject.lab</strong><span>all right reserved</span></span>
							</div>
							<div class="soc-col">
								<a href="#"><img src="/assets/images/vector/footer-soc-be.svg" alt=""></a>
								<a href="#"><img src="/assets/images/vector/footer-soc-dribble.svg" alt=""></a>
								<a href="#"><img src="/assets/images/vector/footer-soc-vimeo.svg" alt=""></a>
								<a href="#"><img src="/assets/images/vector/footer-soc-insta.svg" alt=""></a>
							</div>
						</div>
					</div>

				</div>
			</div>
		</div>

	</div>
</template>
