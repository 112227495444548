<script setup>
import { computed, inject, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import Preloader from '@/layout/Preloader/Preloader.vue'
import ModuleHeader from '@/layout/Header/PageHeader.vue'
import ModuleFooter from '@/layout/Footer/PageFooter.vue'
import smoothScrollbar from 'smooth-scrollbar'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import MainMenu from '@/components/main-menu/main-menu.vue'
import FullVideoModal from '@/components/full-video-modal/full-video-modal.vue'
gsap.registerPlugin(ScrollTrigger)
const isMobile = inject('isMobileCheck')
const smoother = ref()
const scrollElem = ref()
const appHolderElem = ref()
const smoothContentEl = ref()
const route = useRoute()
const layout = computed(() => {
	return route.meta.layout
})
import { useStore } from 'vuex'
import CircleFollower from '@/components/circleFollower/circle-follower.vue'
const store = useStore()
const fullModalOpened = computed(() => store.getters['mainInterface/fullVideoOpenModal'])
let scrollbar

onMounted(() => {
	console.log(`mounted app`)

	// window.onpopstate = function () {
	// 	location.reload()
	// }
	var overscrollOptions = {
		enable: true,
		effect: navigator.userAgent.match(/Android/) ? 'glow' : 'bounce',
		damping: 0.2,
		maxOverscroll: 150,
		glowColor: '#222a2d'
	}
	if (!isMobile.value) {
		scrollbar = smoothScrollbar.init(scrollElem.value, {
			damping: 0.07,
			delegateTo:appHolderElem.value
		})
		store.commit('preloader/scrollbarInstance', scrollbar)

		ScrollTrigger.scrollerProxy(scrollElem.value, {
			scrollTop(value) {
				if (arguments.length) {
					scrollbar.scrollTop = value // setter
				}
				return scrollbar.scrollTop // getter
			}
		})
		scrollbar.addListener(ScrollTrigger.update)
	}

	ScrollTrigger.defaults({ scroller: scrollElem.value, invalidateOnRefresh: true })
})
</script>

<template>
	<div class="app-holder" ref="appHolderElem">
		<preloader />
		<module-header />
		<div class="page-main-wrap" >
			<div class="page-holder" ref="smoothWrapperEl">

				<div class="main-page-holder" id="scrolled" ref="scrollElem">
					<div class="all-page-wrapper">
						<component :is="layout">
							<router-view />
						</component>
					</div>
				</div>
			</div>
		</div>

		<main-menu />
		<module-footer />
		<Transition name="fade">
			<full-video-modal @close="store.commit('mainInterface/fullModalOpened', false)" v-if="fullModalOpened === true" />
		</Transition>
		<circle-follower />
	</div>

</template>

<style></style>
