<script setup>
import { onMounted, onUnmounted, computed, ref, inject, watch, nextTick } from 'vue'
import { gsap } from 'gsap'
import './starfield.scss'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
const isMobile = inject('isMobileCheck')
import { useStore } from 'vuex'
gsap.registerPlugin(ScrollTrigger)
const store = useStore()
let canvasWidth
let canvasHeight
let canvas
let boda
const effectElem = ref()
const starFieldElem = ref()
let ctx, imageData, pix, center, mouseActive, mouseDown, mousePos
let MATHPI180 = Math.PI / 180
let MATHPI2 = Math.PI * 2
let fov = 600 //кучность в центре
let fovMin = 210
let fovMax = fov
var starHolderCount = 3000
let starHolder = []
let starBgHolder = []
let velocity = 0
let starSpeed = 0
let starSpeedMin = 0
let starSpeedMax = 30
let starDistance = 8000
let starRotation = 0
let scrollTriggerStars
let scrollingAmountUp = 0
let backgroundColor = { r: 0, g: 0, b: 0, a: 0 }
onMounted(() => {
	starfieldInit()
})
onUnmounted(() => {})
let requestAnimFrame
function starfieldInit() {
	canvasWidth = window.innerWidth
	canvasHeight = window.innerHeight
	canvas = document.createElement('canvas')
	boda = document.getElementById('scrolled')
	canvas.setAttribute('width', canvasWidth)
	canvas.setAttribute('height', canvasHeight)
	canvas.oncontextmenu = function (e) {
		e.preventDefault()
	}

	effectElem.value.appendChild(canvas)
	ctx = canvas.getContext('2d')
	imageData = ctx.getImageData(0, 0, canvasWidth, canvasHeight)
	pix = imageData.data

	center = { x: canvas.width / 2, y: canvas.height / 2 }

	//---

	mouseActive = false
	mouseDown = false
	mousePos = { x: center.x, y: center.y }

	//---

	if (isMobile.value) {
		fov = 500
	}

	requestAnimFrame = function() {
		requestAnimationFrame(requestAnimFrame);

	}
	requestAnimFrame();



	addParticles()
	runScrollTrigger()
}

function clearImageData() {
	for (var i = 0, l = pix.length; i < l; i += 4) {
		pix[i] = backgroundColor.r
		pix[i + 1] = backgroundColor.g
		pix[i + 2] = backgroundColor.b
		pix[i + 3] = backgroundColor.a
	}
}

function setPixel(x, y, r, g, b, a) {
	var i = (x + y * canvasWidth) * 4

	pix[i] = r
	pix[i + 1] = g
	pix[i + 2] = b
	pix[i + 3] = a
}

function setPixelAdditive(x, y, r, g, b, a) {
	var i = (x + y * canvasWidth) * 4

	pix[i] = pix[i] + r
	pix[i + 1] = pix[i + 1] + g
	pix[i + 2] = pix[i + 2] + b
	pix[i + 3] = a
}

//---

function drawLine(x1, y1, x2, y2, r, g, b, a) {
	var dx = Math.abs(x2 - x1)
	var dy = Math.abs(y2 - y1)

	var sx = x1 < x2 ? 1 : -1
	var sy = y1 < y2 ? 1 : -1

	var err = dx - dy

	var lx = x1
	var ly = y1

	while (true) {
		if (lx > 0 && lx < canvasWidth && ly > 0 && ly < canvasHeight) {
			setPixel(lx, ly, r, g, b, a)
		}

		if (lx === x2 && ly === y2) break

		var e2 = 2 * err

		if (e2 > -dx) {
			err -= dy
			lx += sx
		}

		if (e2 < dy) {
			err += dx
			ly += sy
		}
	}
}

//---

function addParticle(x, y, z, ox, oy, oz) {
	var particle = {}
	particle.x = x
	particle.y = y
	particle.z = z
	particle.ox = ox
	particle.oy = oy
	particle.x2d = 0
	particle.y2d = 0

	return particle
}

function runScrollTrigger() {
	let refreshStarsAmountScrollUp = 300
	if (isMobile.value) {
		refreshStarsAmountScrollUp = 150
	}
	scrollTriggerStars = ScrollTrigger.create({
		trigger: starFieldElem.value,
		start: 'top bottom',
		end: () => 'bottom top',
		pin: effectElem.value,
		scrub: true,
		onToggle: (self) => {

		},
		onUpdate: (self) => {
			/// console.log(self)
			velocity = self.getVelocity() / 60

			if(self.direction < 0){
				velocity = self.getVelocity() / 120
			}
			starSpeed = velocity
			render()
			// if (self.progress < 0.01) {
			// 	starSpeed = 0
			// }
			// if (self.direction === -1) {
			// 	scrollingAmountUp += 1
			// 	if (scrollingAmountUp > refreshStarsAmountScrollUp) {
			// 		scrollingAmountUp = 0
			// 		addParticles()
			// 	}
			// }
		}
	})
}

function addParticles() {
	var i

	var x, y, z

	var colorValue
	var particle



	for (i = 0; i < starHolderCount; i++) {
		x = Math.random() * 10000 - 5000
		y = Math.random() * 10000 - 5000
		z = Math.round(Math.random() * starDistance) //Math.random() * 700 - 350;

		colorValue = Math.floor(Math.random() * 155) + 100

		particle = addParticle(x, y, z, x, y, z)
		particle.color = { r: colorValue, g: colorValue, b: colorValue, a: 255 }
		particle.oColor = { r: colorValue, g: colorValue, b: colorValue, a: 255 }
		particle.w = 1
		particle.distance = starDistance - z
		particle.distanceTotal = Math.round(starDistance + fov - particle.w)

		starHolder.push(particle)
	}
}



function render() {
	clearImageData()

	//---

	var i, j, l, k, m, n

	//---

	var rx, rz

	var star
	var scale

	if (starSpeed > starSpeedMax) starSpeed = starSpeedMax

	if (starSpeed <= 0.5 && starSpeed >= 0) {
		starSpeed = 0
	}
	if (starSpeed >= -0.5 && starSpeed <= 0) {
		console.log(`starspead0`)
		starSpeed = 0
	}
	//---

	var warpSpeedValue

	if (isMobile.value) {
		warpSpeedValue = starSpeed * (starSpeed / starSpeedMax)
	} else {
		warpSpeedValue = starSpeed * (starSpeed / (starSpeedMax / 2))
	}

	for (i = 0, l = starHolder.length; i < l; i++) {
		star = starHolder[i]

		star.z -= starSpeed
		star.distance += starSpeed

		if (star.z < -fov + star.w) {
			star.z = starDistance
			star.distance = 0
		}

		//---
		//star color

		var distancePercent = star.distance / star.distanceTotal

		star.color.r = Math.floor(star.oColor.r * distancePercent)
		star.color.g = Math.floor(star.oColor.g * distancePercent)
		star.color.b = Math.floor(star.oColor.b * distancePercent)

		//---
		//star draw

		scale = fov / (fov + star.z)

		star.x2d = star.x * scale + center.x
		star.y2d = star.y * scale + center.y

		if (star.x2d > 0 && star.x2d < canvasWidth && star.y2d > 0 && star.y2d < canvasHeight) {
			setPixelAdditive(star.x2d | 0, star.y2d | 0, star.color.r, star.color.g, star.color.b, 255)
		}

		// ЗАКОМЕНТИТЬ ЕСЛИ НЕ НУЖЕН ВАРП ->>>
		if (starSpeed != starSpeedMin) {
			var nz = star.z + warpSpeedValue

			scale = fov / (fov + nz)

			var x2d = star.x * scale + center.x
			var y2d = star.y * scale + center.y

			if (x2d > 0 && x2d < canvasWidth && y2d > 0 && y2d < canvasHeight) {
				drawLine(star.x2d | 0, star.y2d | 0, x2d | 0, y2d | 0, star.color.r, star.color.g, star.color.b, 255)
			}
		}
		// <<<-- ЗАКОМЕНТИТЬ ЕСЛИ НЕ НУЖЕН ВАРП
	}

	//---

	ctx.putImageData(imageData, 0, 0)

	//---

	if (mouseActive) {
		// поворот перспективы в зависимости от положения мыши по оси х
		// center.y += ( mousePos.y - center.y ) * 0.010;
		// center.x += ( mousePos.x - center.x ) * 0.010;
	} else {
		center.x += (canvas.width / 2 - center.x) * 0.015
	}
}
function mouseMoveHandler(event) {
	mousePos = getMousePos(canvas, event)
}

function mouseEnterHandler(event) {
	//mouseActive = true;
	starRotation -= 0.5
}

function mouseLeaveHandler(event) {
	mouseActive = false

	mouseDown = false
}

//---

function getMousePos(canvas, event) {
	var rect = canvas.getBoundingClientRect()

	return { x: event.clientX - rect.left, y: event.clientY - rect.top }
}

//---

function touchStartHandler(event) {
	event.preventDefault()

	mouseDown = true
	mouseActive = true
}

function touchEndHandler(event) {
	event.preventDefault()

	mouseDown = true
	mouseActive = false
}

function touchMoveHandler(event) {
	event.preventDefault()

	mousePos = getTouchPos(canvas, event)
}

function touchCancelHandler(event) {
	mouseDown = false
	mouseActive = false
}

//---

function getTouchPos(canvas, event) {
	var rect = canvas.getBoundingClientRect()

	return { x: event.touches[0].clientX - rect.left, y: event.touches[0].clientY - rect.top }
}
</script>

<template>
	<div ref="starFieldElem" class="starField">
		<div id="effect" ref="effectElem" class="effect"></div>
	</div>
</template>
