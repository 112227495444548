<script setup>
import { inject, computed, reactive, ref, onMounted, nextTick } from 'vue'
import { useStore } from 'vuex'
import { switchLanguage } from '@/i18n'
import './Header.scss'
const emitter = inject('emitter')
const store = useStore()

import { gsap, Power2, Expo, Power1 } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { ScrollSmoother } from 'gsap/ScrollSmoother'
import { onBeforeRouteLeave } from 'vue-router'
import html2canvas from 'html2canvas'
gsap.registerPlugin(ScrollTrigger)

const isShowedLangMenu = ref(false)
const currentLang = ref('ru')
const langList = ref(['ru', 'en'])
const otherLang = ref('en')
const rowElem = ref()
const logoElem = ref()
onMounted(() => {
	console.log(`mounted index second section`)
})
nextTick(() => {
	animations()
})
function changeLang() {
	const code = otherLang.value
	otherLang.value = currentLang.value
	currentLang.value = code
	switchLanguage(code)
}
function animations() {
	const TL2 = gsap.timeline({
		scrollTrigger: {
			start: 0,
			scrub: true,
			end: '+=' + window.innerHeight,
			invalidateOnRefresh: true,
			onToggle: (self) => {
				if (self.progress >= 1) {
					logoElem.value.classList.add('small')
				} else {
					logoElem.value.classList.remove('small')
				}
			}
		}
	})
	TL2.fromTo(rowElem.value, { y: 0 }, { y: -200, ease: 'none' }, 0)
	TL2.fromTo(logoElem.value, { scale: 1 }, { scale: 0.6, ease: 'none' }, 0)
}

function handleWorkLinkClick(workSlug) {
	console.log(workSlug)

	store.commit('preloader/loadPath', `/works/${workSlug}`)

	store.commit('preloader/goNextPage', true)
}
</script>
<template>
	<header class="header">
		<div class="container">
			<div class="header-holder">
				<div class="header-row">
					<div class="left-col">
						<div class="logo-wrap" ref="logoElem">
							<router-link :to="{ name: 'FrontPage' }" class="logo">
								<img src="/assets/images/vector/logo.svg" alt="" />
							</router-link>
						</div>
					</div>
					<div class="right-col">
						<ul class="main-nav" ref="rowElem">
							<li>
								<router-link :to="{ name: 'FrontPage' }">
									<span>Home</span>
								</router-link>
							</li>
							<li>
								<router-link :to="{ name: 'AboutPage' }">
									<span>About</span>
								</router-link>
							</li>
							<li>
								<router-link :to="{ name: 'Works' }">
									<span>Works</span>
								</router-link>
							</li>
						</ul>
					</div>
				</div>

				<div class="soc-links">
					<a href="#"><img src="/assets/images/vector/soc-telegram.svg" alt="" /></a>
				</div>
			</div>
		</div>
	</header>
</template>
