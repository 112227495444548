<script setup>
import { inject, computed, reactive, ref, onMounted, nextTick, onUpdated, watch } from 'vue'
import { useStore } from 'vuex'
import { switchLanguage } from '@/i18n'
import './Header.scss'
const emitter = inject('emitter')
const store = useStore()

import { gsap, Power2, Expo, Power1 } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { ScrollSmoother } from 'gsap/ScrollSmoother'
import { useRoute } from 'vue-router'
const route = useRoute()
gsap.registerPlugin(ScrollTrigger)


const rowElem = ref()
const logoElem = ref()
const leftColElem = ref()
const isWorkView = computed(() => {
	if(route.matched[0]){
		return route.matched[0].name === 'Works'
	}
})

onMounted(() => {
	console.log(`'mount`)
	//console.log(route.matched[0].name)
})
onUpdated(() => {
	//console.log(route.matched[0].name)
})
nextTick(() => {
	animations()
})

function animations() {
	const TL2 = gsap.timeline({
		scrollTrigger: {
			start: 'top top',
			scrub: true,
			end: 'bottom top',
			invalidateOnRefresh: true,
			onToggle: (self) => {
				if (self.progress >= 1) {
					logoElem.value.classList.add('small')
				} else {
					logoElem.value.classList.remove('small')
				}
			}
		}
	})
	TL2.fromTo(rowElem.value, { y: 0 }, { y: -200, ease: 'none' }, 0)
	TL2.fromTo(leftColElem.value, { opacity: 1 }, { opacity: 0, duration:0.2, ease: 'none' }, 0)
	TL2.fromTo(logoElem.value, { scale: 1 }, { scale: 0.6, ease: 'none' }, 0)
}

function handleWorkLinkClick(workSlug) {
	console.log(workSlug)
	store.commit('preloader/loadPath', `/works/${workSlug}`)
	store.commit('preloader/goNextPage', true)
}
</script>
<template>
	<header class="header" :class="{ 'work-view': isWorkView }">
		<div class="container">
			<div class="header-holder">
				<div class="header-row">
					<div class="all-proj-col"  v-if="isWorkView === true ">
						<button class="all-proj-btn" type="button">
							<span class="quads"><i></i><i></i><i></i><i></i></span>
							<strong>all works</strong>
						</button>
					</div>
					<div class="left-col" ref="leftColElem">
						<div class="logo-wrap" ref="logoElem">
							<router-link :to="{ name: 'FrontPage' }" class="logo">
								<img src="/assets/images/vector/logo.svg" alt="" />
							</router-link>
						</div>
					</div>
					<div class="right-col">
						<div class="nav-wrapper" ref="rowElem" >
							<ul class="main-nav" v-if="!isWorkView ">
								<li>
									<router-link :to="{ name: 'Works' }"
												 data-link-text="works"
												 @mouseenter="store.commit('mainInterface/cursorHover', 'linkHover')"
												 @mouseleave="store.commit('mainInterface/cursorHover', '')">
										<span>works</span>
									</router-link>
								</li>
								<li>
									<router-link :to="{ name: 'AboutPage' }"
												 data-link-text="about"
												 @mouseenter="store.commit('mainInterface/cursorHover', 'linkHover')"
												 @mouseleave="store.commit('mainInterface/cursorHover', '')">
										<span>about</span>
									</router-link>
								</li>
								<li>
									<router-link :to="{ name: 'Prices' }"
												 data-link-text="services"
												 @mouseenter="store.commit('mainInterface/cursorHover', 'linkHover')"
												 @mouseleave="store.commit('mainInterface/cursorHover', '')">
										<span>services</span>
									</router-link>
								</li>
							</ul>
						</div>

						<div class="soc-links">
							<a href="#"><img src="/assets/images/vector/soc-telegram.svg" alt="" /></a>
						</div>
					</div>
				</div>

			</div>
		</div>
	</header>
</template>
