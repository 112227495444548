<script setup>
import { computed, inject, onMounted, onUnmounted, ref } from 'vue'
import { useStore } from 'vuex'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import './PricesPage.scss'
import loaderImg from '@/components/Loader/loader-img.vue'
import LoaderSequence from '@/components/Loader/loader-sequence.vue'
import ImgLoadScroll from '@/components/ImageLoadOnScrol/img-load-scroll.vue'
import SkarallaxBlock from '@/components/skarallax/skarallax-block.vue'
import OurServicesSection from '@/views/PricesPage/OurServicesSection/OurServices.vue'
import WorksSection from '@/views/FrontPage/WorksSection/WorksSection.vue'
import IndexThirdSection from '@/views/FrontPage/index-third-section.vue'
import IndexSecondSection from '@/views/FrontPage/index-second-section.vue'
import Starfield from '@/components/starfield/starfield.vue'

const store = useStore()
const isPlayCanvas = ref(false)
const isMobile = inject('isMobileCheck')
const isRetina = inject('isRetina')

gsap.registerPlugin(ScrollTrigger)
function handleTest() {
	isHighDensity()
}
function handleLoadAllPictures() {
	store.dispatch('preloader/actReadyToLoad', true)
}
const loaderAllPicsAmount = computed(() => {
	return store.getters['preloader/allPicsAmount']
})
const picsToLoad = computed(() => {
	return store.getters['preloader/picsToLoad']
})

const isRetinaComp = computed(() => {
	return isRetina
})
onMounted(() => {
	console.log(`mounted prices`)
	store.dispatch('preloader/actReadyToLoad', true)
})
function isHighDensity() {
	console.log(isRetina.value)
}
onUnmounted(() => {
	console.log(`unmount prices`)
	store.dispatch('preloader/resetPreloader')
})
</script>
<template>
	<div class="about-page">
		<our-services-section />
		<div class="twoSectsWrap">
			<Starfield />
			<works-section/>
		</div>

		<strong>
			Осталось <b style="color: red">{{ picsToLoad }}</b> из <i style="font-style: normal; color: green">{{ loaderAllPicsAmount }}</i> картинок
		</strong>
		<button @click="handleLoadAllPictures">Загрузить все в очереди</button>

		<div
			class="spacer"
			style="
				height: 1000px;
				background: #666;
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				justify-content: center;
				align-items: center;
				border: 5px dashed #fdd094;
			"
		>
			<span>spacer</span>
		</div>

		<div
			class="spacer"
			style="
				height: 1000px;
				background: #666;
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				justify-content: center;
				align-items: center;
				border: 5px dashed #fdd094;
			"
		>
			<span>spacer1</span>
		</div>

		<loader-sequence path="assets/images/static/sequence2" :is-play="isPlayCanvas" :play-duration="2.5" :start-frame="1" :end-frame="100" />
	</div>
</template>
