<script setup>
import './WorksSection.scss'
import { ref, nextTick, onMounted, computed, watch, onUnmounted, onBeforeUnmount } from 'vue'
import { useStore } from 'vuex'

const store = useStore()

import { gsap, Power2, Expo, Power1, Linear } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import LoaderImg from '@/components/Loader/loader-img.vue'
import { CustomEase } from 'gsap/CustomEase'
import LoaderPic from '@/components/Loader/loader-pic.vue'
import LoaderVideo from '@/components/Loader/loader-video.vue'

gsap.registerPlugin(ScrollTrigger)

const props = defineProps({
	triggerElem: {
		type: Object,
	},
})
let worksTLFadeIn
let itemScaleAnimationTL
let itemScaleAnimationTL2
let sectTitleTL
const sectTitleElem = ref()
let titleAnimationTL = gsap.timeline({
	paused: true
})
const thisSect = ref()
const sectHolder = ref()
const worksList = ref()

let itemElems
let itemVideo
let itemElemsTitles
const isAllLoaded = computed(() => {
	return store.getters['preloader/isAllLoaded']
})
const activedElem = ref(3)
const itemRefs = ref([])
const itemPics = ref([])
const itemTitles = ref([])
const picFrameElems = ref([])
const lastWorksList = ref([
	{
		title: 'Ganntec',
		subtitle: 'Media solutions',
		picture: 'ganntec-thumb.webp',
		video: {
			filename:'ganntec2',
			mp4: false,
			webm: true,
			play:false,
		}
	},
	{
		title: 'YAY Network',
		subtitle: 'Media solutions',
		picture: 'yay.webp',
		video: {
			filename:'yay',
			mp4: true,
			webm: true,
			play:false,
		}
	},
	{
		title: 'Crypto Bank',
		subtitle: 'Media solutions',
		picture: 'crypto-bank.webp',
		video: {
			filename:'crypto-bank',
			mp4: true,
			webm: true,
			play:false,
		}
	},
	{
		title: 'Spinsy',
		subtitle: 'Media solutions',
		picture: 'spinsy.webp',
		video: {
			filename:'spinsy',
			mp4: true,
			webm: true,
			play:false,
		}
	},
	{
		title: 'Flussonic',
		subtitle: 'Media solutions',
		picture: 'flussonic.webp',
		video: {
			filename:'flussonic',
			mp4: true,
			webm: false,
			play:false,
		}
	},

	{
		picture: 'solixie.webp',
		title: 'Solixie',
		subtitle: 'Media solutions',
		video: {
			filename:'solixie',
			mp4: true,
			webm: true,
			play:false,
		}
	}

])
watch(
	() => isAllLoaded.value,
	(allLoaded) => {
		console.log(`all loaded from workssection`)
		animations()
	},
	{ immediate: false }
)
onMounted(() => {
	console.log(`mounted index services`)

	console.log(itemTitles.value)
})
onBeforeUnmount(() => {})

function animations() {
	worksTLFadeIn = gsap.timeline({
		scrollTrigger: {
			trigger: props.triggerElem,
			start: 'top bottom',
			end: 'top 70%',
			scrub: true,
			invalidateOnRefresh: true
		}
	})
	worksTLFadeIn.fromTo(
		worksList.value,
		{  opacity: 0 },
		{  opacity: 1, ease: Linear.easeNone },
		0
	)

	itemScaleAnimationTL = gsap.timeline({
		scrollTrigger: {
			trigger: props.triggerElem,
			start: 'top 180%',
			end: 'bottom bottom',
			scrub: true,
			invalidateOnRefresh: true,
		}
	})

	itemRefs.value.forEach((item, i) => {
		itemScaleAnimationTL.add(
			gsap.fromTo(
				item,
				{ z: -5000, opacity:0},
				{
					z: 1000,
					opacity:3,
					delay:0.1,
					onUpdate: function() {
						lastWorksList.value[i].video.play = true
						//console.log(this.progress())
					},
					onComplete() {
						console.log("completed", i,);
						lastWorksList.value[i].video.play = false
					},
					onReverseComplete() {
						console.log("stop", i,);
						lastWorksList.value[i].video.play = false
					},
					ease: Linear.easeNone
				}
			), "<+=0.03"
		)


	});

	// itemPics.value.forEach((pic, i) => {
	// 	itemScaleAnimationTL.add(
	// 		gsap.fromTo(
	// 			pic,
	// 			{ opacity:1},
	// 			{
	// 				opacity:0,
	// 				delay:0.1,
	// 				ease: Linear.easeNone
	// 			}
	// 		), "<+=0.17"
	// 	)
	// });
	// itemScaleAnimationTL.fromTo(
	// 	itemRefs.value,
	// 	{ z: -5000 },
	// 	{ z: 1000, stagger: {each: 0.17, onComplete: onComplete , onReverseComplete: onReverseCompl, onUpdate: onUpdate }, ease: Linear.easeNone },
	// 	0)
	// itemScaleAnimationTL.fromTo(
	// 	itemRefs.value,
	// 	{ opacity: 0 },
	// 	{ opacity: 1, duration: 0.1, stagger: 0.17, ease: Linear.easeNone },
	// 	0
	// )

	itemScaleAnimationTL2 = gsap.timeline({
		scrollTrigger: {
			trigger: props.triggerElem,
			start: 'top 180%',
			end: 'bottom bottom',
			scrub: true,
			invalidateOnRefresh: true,
		}
	})
	itemScaleAnimationTL2.fromTo(itemPics.value, { opacity:1 }, { opacity:0, duration:0.1,   stagger: 0.19, ease: Linear.easeNone }, 0.8)


	//section title Animation IN
	sectTitleTL = gsap.timeline({
		scrollTrigger: {
			trigger: props.triggerElem,
			start: 'top bottom',
			end: 'top center',
			scrub: true,
			invalidateOnRefresh: true
		}
	})
	sectTitleTL.fromTo(
		sectTitleElem.value,
		{  opacity: 0 },
		{  opacity: 1, ease: Linear.easeNone },
		0
	)
}

</script>
<template>
	<div class="works-section" ref="thisSect">
		<div class="sect-holder" ref="sectHolder">
			<div class="works-tizer-list" ref="worksList">
				<div class="work-item" v-for="item in lastWorksList" ref="itemRefs">
					<div class="item-pic-wrap" @mouseenter="store.commit('mainInterface/cursorHover', 'discover')" @mouseleave="store.commit('mainInterface/cursorHover', '')">
						<a href="works/flussonic/" class="abs-link" ></a>
						<div class="item-titles" ref="itemTitles">
							<strong>{{ item.title }}</strong>
							<span>{{ item.subtitle }}</span>
						</div>
						<div class="item-pic" ref="itemPics">
							<div class="pic-holder" ref="picHolderElems">
								<div class="pic-frame" ref="picFrameElems">
									<loader-video
										ref="itemVideoElems"
										v-if="item.video"
										path="./assets/images/static/works-tizer-list/video"
										:fileName="item.video.filename"
										:webm="item.video.webm"
										:mp4="item.video.mp4"
										:autoplay="false"
										:thumb-video-play="item.video.play"
										:loop="true">
										<div class="thumb">
											<loader-pic :src="`./assets/images/static/works-tizer-list/${item.picture}`" :retina="true" />
										</div>
									</loader-video>
									<loader-pic
										v-else
										:src="`./assets/images/static/works-tizer-list/${item.picture}`"
										:retina="true"
										retina-src="portfolio/axolot/images/logo-2x.png"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="sect-title-box"  ref="sectTitleElem">
				<div class="title-holder">
					<span class="sect-title">our <br>cases</span>
				</div>
			</div>
		</div>
	</div>
</template>
