import { createStore } from 'vuex'
import preloader from '@/store/Preloader'
import mainInterface from '@/store/Interface'

const store = createStore({
	modules: {
		preloader,
		mainInterface
	}
})

export default store
