<script setup>
import { computed, inject, onMounted, onUnmounted } from 'vue'
import { useStore } from 'vuex'
import './FrontPage.scss'
//my components
import loaderImg from '@/components/Loader/loader-img.vue'
import LoaderSequence from '@/components/Loader/loader-sequence.vue'
import ImgLoadScroll from '@/components/ImageLoadOnScrol/img-load-scroll.vue'
import SkarallaxBlock from '@/components/skarallax/skarallax-block.vue'
//sections
import IndexFirstSection from '@/views/FrontPage/index-first-section.vue'
import IndexSecondSection from '@/views/FrontPage/index-second-section.vue'
import IndexThirdSection from '@/views/FrontPage/index-third-section.vue'
import Starfield from '@/components/starfield/starfield.vue'
import IndexFourSection from '@/views/FrontPage/index-four-section.vue'

//variables
const emit = defineEmits(['mount'])
const store = useStore()

onMounted(() => {})
onUnmounted(() => {})
</script>
<template>
	<div class="twoSectsWrap">
		<Starfield />
		<index-second-section />
		<index-third-section />
		<index-four-section />
	</div>
</template>
